.PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.25rem 0.7rem;
    font-size: 0.825rem;
    font-weight: 400;
    line-height: 1.625;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.PhoneInputInput:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9fc0f5;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(63, 128, 234, 0.25);
}