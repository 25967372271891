.accountDescTitle {
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  margin-bottom: 10px;
}

ul.accountDescUl {
  padding-inline-start: 0;
  padding-left: 0;
}

.accountDescUl li {
  line-height: 0.6rem;
}

.accountDescRow {
  display: flex;
  justify-content: space-between;
}

.accountDescRow > div {
  margin-bottom: 16px;
  display: flex;
  flex: 1;
}

.accountDescRow span.descItemName::after {
  content: " ";
  flex: 1;
  border-bottom: 2px dotted rgb(194, 190, 190);
}

.descItemName {
  font-size: 0.9rem;
  flex: 1;
  display: flex;
}

.descItemValue {
  font-size: 0.9rem;
}

.descInvestorInfo {
  font-size: 0.9rem;
  color: black !important;
}

.descInvestorPropertyName{
  margin-right: 5px;  
}

.descInvestorProperty{
  margin-right: 30px;  
}

.descBalance{
  font-size: 1.1rem;
  color: black !important;
}

.accountButtons {
  margin: 10px auto;
  text-align: center;
}

.accountButtons button {
  margin-right: 10px;
}
.accountButtons button:last-child {
  margin-right: 0;
}
