.titleChart {
  display: flex;
  justify-content: space-between;
}

.radioFilterButton {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.radioFilterButton:focus, :active {
  outline: none !important;
  box-shadow: none;
}
